import React from "react";
import AffirmPrequalify from "../AffirmPrequalify/AffirmPrequalify";

export default function N3EliteBundleLanding({ eliteData, handleAddToCart }) {
  return (
    <>
      <div className="elite-exp-container" id="buy-now">
        <div className="elite-exp-wrapper">
          <h2>
            <span style={{ color: "#fc5310" }}>CHOOSE</span> YOUR N3 ELITE{" "}
            <span style={{ color: "#fc5310" }}>EXPERIENCE</span>
          </h2>
        </div>
      </div>
      <div className="prod-elite-bundles-container">
        <div className="prod-elite-bundles-wrapper">
          <div className="landing-bundles-row">
            {eliteData &&
              eliteData?.length > 0 &&
              eliteData.map((item, index) => (
                <div className="landing-bundles-col" key={index}>
                  <div className="product-exp-container">
                    {index === 0 && (
                      <div className="elite-bestseller">
                        <div>
                          <img
                            src="https://nexersys.com/wp-content/uploads/2021/09/best-seller-flag.svg"
                            alt="best seller"
                          />
                        </div>
                      </div>
                    )}
                    <div
                      className={`product-exp-wrapper ${
                        index === 0 && `elite-bg-highlight`
                      }`}
                    >
                      <div className="product-elite-title-wrapper">
                        <h3>{item?.title}</h3>
                      </div>
                      <div className="product-elite-image-wrapper">
                        {index === 1 ? (
                          <img
                            src={item.landingImage?.url}
                            alt={item?.alternativeText}
                          />
                        ) : (
                          <img
                            src={item.landingImage?.url}
                            alt={item?.alternativeText}
                          />
                        )}
                      </div>
                      <div className="product-elite-price-wrapper">
                        {item.newPrice && (
                          <div className="elite-actual-price">
                            Bundle Value: {`$${item.price}`}
                          </div>
                        )}
                        <div
                          className={`elite-discounted-price ${
                            !item.newPrice && `actualprice-padding`
                          }`}
                        >
                          {item.newPrice ? (
                            <span>
                              YOUR PRICE:{" "}
                              <span className="accent">{`$${item.newPrice}`}</span>
                            </span>
                          ) : (
                            <span>{`$${item.price}`}</span>
                          )}
                        </div>
                      </div>
                      <div className="product-elite-button-wrapper">
                        <button
                        onClick={() => handleAddToCart(item)}
                        >
                          ADD TO CART
                        </button>
                      </div>
                      {item.price > 0 && item.price && (
                        <AffirmPrequalify
                          price={item.price}
                          newPrice={item.newPrice}
                        />
                      )}
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
}
