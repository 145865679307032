import React, { useEffect, useRef } from "react";
import config from "../../config/config";
import API from "../../services/httpService";
import appService from "../../services/appService";
import { eventAddToCart } from "../../helpers/events";
import N3EliteBundleLanding from "./N3EliteBundleLanding";
import { connect } from "react-redux";
import Link from "next/link";

declare const window: any;
const MilitaryDiscount = ({
  data,
  isBadge = true,
  eliteData,
  landing = false,
  onToggle,
  showLoader,
  hideLoader,
  setCartItems,
}: any) => {
  const ref = useRef(null);
  const dataMilitary = isBadge ? data[0] : data;
  const cardtId = appService.getKey("cartid");
  useEffect(() => {
    if (window.affirm && window.affirm.ui) {
      try {
        window.affirm.ui.refresh();
      } catch (err) {}
    }
    const handleResize = () => {
      if (ref.current) {
        if (window.innerWidth > 960) {
          ref.current.style.backgroundImage = `url(${dataMilitary?.image?.url})`;
        } else {
          ref.current.style.backgroundImage = `url(${dataMilitary?.tablet_image?.url})`;
        }
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const GoogleAnalyticsAddToCart = () => {
    let prod = {};
    let price;
    let discount = data.newPrice !== null ? data.price - data.newPrice : 0;
    price = data.newPrice !== null ? data.newPrice : data.price;

    prod = {
      ...prod,
      id: data.id,
      name: data.displayTitle ? data.displayTitle : data.title,
      discount: discount ? +Math.abs(discount).toFixed(2) : 0,
      affiliation: "Nexersys Store",
      brand: "Nexersys",
      // item_category: 'pants',
      price: price,
      currency: data.currency
        ? data.currency.code
          ? data.currency.code
          : "USD"
        : "USD",
      quantity: 1,
    };

    const item = {
      currency: "USD",
      items: [prod],
      value: price,
    };
    eventAddToCart(item);
  };

  const handleAddToCart = (res) => {
    const VariantOptions = [];

    res.Includes.map((data, index) => {
      let tempValue = {};
      if (data.__component === "product.fixed-product") {
        if (data.selectedVariant) {
          if (data.product.title === "Sit Up Bar") {
            tempValue["product"] = data.product.id;
            tempValue["variantLabel"] = data.selectedVariant.variantLabel;
            tempValue["selectedOption"] = data.selectedVariant.selectedOption;
            tempValue[
              "variantKey"
            ] = `0_${data.product.id}_${data.selectedVariant.variantLabel}`;
            VariantOptions.push(tempValue);
            tempValue = {};
          } else {
            for (let i = 0; i < data.selectedVariant.length; i++) {
              tempValue["product"] = data.product.id;
              tempValue["variantLabel"] = data.selectedVariant[i].variantLabel;
              tempValue["selectedOption"] =
                data.selectedVariant[i].selectedOption;
              tempValue[
                "variantKey"
              ] = `0_${data.product.id}_${data.selectedVariant[i].variantLabel}`;
              VariantOptions.push(tempValue);
              tempValue = {};
            }
          }
        } else {
          if (index !== 0) {
            tempValue["product"] = data.product.id;
            tempValue["variantKey"] = `0_${data.product.id}`;
            VariantOptions.push(tempValue);
            tempValue = {};
          }
        }
      }
      if (data.__component === "product.choice-of-product") {
        for (let j = 0; j < data.selectedProduct.length; j++) {
          tempValue["product"] = data.OptionalProducts[j].product.id;
          tempValue["variantLabel"] = data.selectedProduct[j].variantLabel;
          tempValue["selectedOption"] = data.selectedProduct[j].selectedOption;
          tempValue[
            "variantKey"
          ] = `0_${data.OptionalProducts[j].product.id}_${data.selectedProduct[j].variantLabel}`;
          VariantOptions.push(tempValue);
          tempValue = {};
        }
      }
    });
    showLoader();
    GoogleAnalyticsAddToCart();

    const request = { productBundleId: res.id, quantity: 1, VariantOptions };
    API.invokeApi(
      {
        method: "put",
        url: `${config.endpoints.getCart}/${cardtId}/cartitems`,
        data: request,
      },
      (success: boolean, res: any) => {
        if (success && res.id) {
          // setVarOptions([]);
          const request: any = { method: "get", url: config.endpoints.getCart };
          if (cardtId) {
            request.headers = { cartid: cardtId };
          }
          API.invokeApi(request, (success: boolean, resp: any) => {
            if (success && resp.cartId) {
              appService.setKey("cartid", resp.cartId);
              setCartItems(resp);
              hideLoader();
            } else {
              hideLoader();
            }
            // onCloseHandler();
            onToggle();
          });
        } else {
          hideLoader();
        }
      }
    );
  };

  return (
    data &&
    dataMilitary && (
      <>
        <div className={`${landing ? `militiry-discount-landing-wrapper` : `militiry-discount-wrapper`}`}>
          <div className="militiry-discount" ref={ref}>
            <div
              className={`military-info ${landing && `military-info-white`}`}
            >
              {landing ? (
                <h2
                  className="military-title"
                  dangerouslySetInnerHTML={{ __html: dataMilitary?.label }}
                />
              ) : (
                <h2
                  className={`military-title ${
                    !isBadge ? `military-title-300` : `military-title-bold`
                  }`}
                >
                  {dataMilitary.label}
                </h2>
              )}
              <p className="military-sub-title">{dataMilitary.subTitle}</p>
              {dataMilitary.learnMoreLabel && (
                <Link href={`/heroes-discount/`}>
                  <a
                    className={`learn-more-title ${
                      landing && `learn-more-title-white`
                    }`}
                    draggable={false}
                    tabIndex={-1}
                  >
                    {dataMilitary.learnMoreLabel}
                  </a>
                </Link>
              )}
            </div>
          </div>
        </div>
        {landing && (
          <N3EliteBundleLanding eliteData={eliteData} handleAddToCart={handleAddToCart} />
        )}
      </>
    )
  );
};

const mapDispatch = (dispatch: any) => {
  return {
    onToggle: () => dispatch({ type: "TOGGLE_CART" }),
    setCartItems: (cartItems: any) => {
      dispatch({ type: "ON_CART_LOAD", payload: cartItems });
    },
    showLoader: () => dispatch({ type: "LOADER_SHOW" }),
    hideLoader: () => dispatch({ type: "LOADER_HIDE" }),
  };
};

export default connect(null, mapDispatch)(MilitaryDiscount);
